* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  line-height: 1.3em;
  font-size: 16px;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -0.04em;
  font-weight: 700;
}

h1 {
  font-size: 70px;
  font-weight: 400;
  font-family: 'Open Sans Condensed', sans-serif;
  letter-spacing: 0em;
}

h2 {
  font-size: 50px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 26px;
}

p {
  font-weight: 400;
  color: #333;
  line-height: 1.4em;
  margin-bottom: 25px;
}

ul{  
  list-style-position: outside;
  padding-left: 25px;
}

li{
  margin-bottom: 4px;
}

.ptb{padding: 5rem 0;}
.plr{padding-left: 3rem; padding-right: 3rem;}
.pt{padding: 5rem 0 0 0;}
.pb{padding: 0 0 5rem 0;}
.np{padding: 0 !important;}
.nm{margin: 0;}
.mtb{margin: 5rem 0;}
.mt{margin: 5rem 0 0 0;}
.mb{margin: 0 0 5rem 0 !important;}
.text-white{color: #fff;}
.text-orange{color: #FCC40A !important;}
.text-center{text-align: center;}
.italic{font-style: italic;}
a{transition: all 300ms ease-in-out;}
.align-start{align-items: flex-start !important;}

.inverse{flex-direction: row-reverse;}

.delay-100 {
  animation-delay: 0s;
  transition-delay: 0s;
}
.delay-200 {
  animation-delay: 0.2s;
  transition-delay: 0.2s;
}
.delay-300 {
  animation-delay: 0.3s;
  transition-delay: 0.3s;
}
.delay-400 {
  animation-delay: 0.4s;
  transition-delay: 0.4s;
}
.delay-500 {
  animation-delay: 0.5s;
  transition-delay: 0.5s;
}
.delay-600 {
  animation-delay: 0.6s;
  transition-delay: 0.6s;
}
.delay-700 {
  animation-delay: 0.7s;
  transition-delay: 0.7s;
}
.delay-800 {
  animation-delay: 0.8s;
  transition-delay: 0.8s;
}

@media screen and (max-width: 768px) {
  h1 {font-size: 48px;}
  h2 {font-size: 38px;}  
  .ptb{padding: 3rem 0;}
  .pt{padding: 3rem 0 0 0;}
  .pb{padding: 0 0 3rem 0;}
  .mtb{margin: 3rem 0;}
  .mt{margin: 3rem 0 0 0;}
  .mb{margin: 0 0 3rem 0;}
}

@media screen and (max-width: 576px) {
  h1{font-size: 40px;}
  h2{font-size: 32px;}  
  .ptb{padding: 2rem 0;}
  .pt{padding: 2rem 0 0 0;}
  .pb{padding: 0 0 2rem 0;}
  .mtb{margin: 2rem 0;}
  .mt{margin: 2rem 0 0 0;}
  .mb{margin: 0 0 2rem 0;}
}
